<script>
import { mapActions, mapGetters } from 'vuex'

const PROVIDER_STRATEGY_MAP = {
    azure: 'oauth2',
    classlink: 'oauth2',
    clever: 'oauth2',
    google: 'oauth2',
    illuminate_google: 'oauth2',
    illuminate: 'password',
    ldap: 'ldap',
    saml: 'saml',
}

const defaultAccountTypes = () => ({
    student: false,
    staff: false,
    parent: false,
    illuminateEmployee: false,
})

export default {
    name: 'AuthConfigProvider',
    props: {
        clientId: {
            type: String,
            default: '',
        },
        // These two props are just cutting corners for form submission.
        id: {
            type: String,
            default: null,
        },
        provider: {
            type: String,
            default: null,
        },
    },
    provide() {
        return {
            get: async (id) => {
                if (!id) {
                    return null
                }
                if (!this.getItemsById[id]) {
                    await this.get({ clientId: this.clientId })
                }

                const config = {
                    ...this.getItemsById[id],
                    ...this.getItemsById[id].options,
                }
                delete config.options
                config.accountTypes = Object.entries(config.accountTypes).reduce((acc, at) => {
                    if (at[1]) {
                        acc.push(at[0])
                    }
                    return acc
                }, [])

                if (config.allowedDomains) {
                    config.allowedDomains = config.allowedDomains.join(', ')
                }
                if (config.xmlUrl || config.metadata) {
                    config.metadata = {
                        data: config.metadata,
                        url: config.xmlUrl,
                    }
                }

                return config
            },
            submit: async (data) => this.submit(data),
        }
    },
    computed: {
        ...mapGetters('authentication', ['getItemsById', 'getDraft', 'getItems']),
        name() {
            let counter = 0
            this.getItems.forEach((i) => {
                if (i.provider === this.provider) {
                    const parts = i.name.split('_')
                    // Check if the name was provider_name_N, allow for _ in names like illuminate_google.
                    if (
                        parts.length >= 2 &&
                        parts.slice(0, parts.length - 1).join('_') === this.provider
                    ) {
                        counter = Math.max(counter, parseInt(parts[parts.length - 1]))
                    }
                }
            })
            return `${this.provider}_${counter + 1}`
        },
    },
    methods: {
        ...mapActions({
            post: 'authentication/post',
            patch: 'authentication/patch',
            get: 'authentication/cget',
        }),
        async submit(formData) {
            let {
                id,
                provider,
                strategy,
                accountTypes,
                displayName,
                enabled,
                name,
                ssoEnabled,
                createdAt,
                updatedAt,
                metadata,
                ...options
            } = formData
            // initialize array if undefined.
            accountTypes = accountTypes ?? []

            const data = {
                authConfig: {
                    accountTypes:
                        accountTypes?.reduce((acc, at) => {
                            acc[at] = true

                            return acc
                        }, defaultAccountTypes()) || [],
                    strategy: strategy ?? PROVIDER_STRATEGY_MAP[this.provider],
                    name: name ?? this.name,
                    displayName,
                    enabled: !!enabled,
                    options,
                    provider: provider ?? this.provider,
                    ssoEnabled: !!ssoEnabled,
                },
            }

            // for auth providers that allow multiple domains, we need to
            // provide them to the API as an array.
            if (data.authConfig.options.allowedDomains) {
                data.authConfig.options.allowedDomains =
                    data.authConfig.options.allowedDomains.split(/[\s,]+/)
            }
            // we only ever want internal users to log in with their company issued email.
            if (data.authConfig.provider === 'illuminate_google') {
                data.authConfig.options.allowedDomains = ['illuminateed.net', 'renaissance.com']
            }

            // deconstruct remote field into the options required for SAML.
            if (data.authConfig.provider === 'saml') {
                //Only send the metadata and xmlUrl keys if they have values otherwise accounts service will complain
                if (metadata.data) {
                    data.authConfig.options.metadata = metadata.data
                } else {
                    delete data.authConfig.options.metadata
                }

                if (metadata.url) {
                    data.authConfig.options.xmlUrl = metadata.url
                } else {
                    delete data.authConfig.options.xmlUrl
                }
            }

            // bypass encryption can get lost because the values aren't initialized,
            // and false is valid. Make sure it's a truthy value.
            if (data.authConfig.provider === 'ldap') {
                data.authConfig.options.bypassEncryption =
                    !!data.authConfig.options.bypassEncryption
            }

            if (id) {
                try {
                    const resp = await this.patch({ id, clientId: this.clientId, data })
                    this.$emit('submitted', resp.data)
                } catch (e) {}
            } else {
                try {
                    const resp = await this.post({ clientId: this.clientId, data })
                    this.$emit('submitted', resp.data)
                } catch (e) {}
            }
            this.get({ clientId: this.clientId })
        },
    },

    render() {
        return this.$slots.default
    },
}
</script>
