import roles from '@/helpers/security/roles.js'

export default [
    {
        headerName: 'Provider',
        field: 'provider',
        sortable: false,
        width: 175,
    },
    {
        headerName: 'Button Text',
        field: 'displayName',
        sortable: false,
    },
    {
        headerName: 'Account Type',
        field: 'accountTypes',
        sortable: false,
        valueFormatter: function (params) {
            if (params.value) {
                return Object.entries(params.value)
                    .reduce(function (items, [at, present]) {
                        if (present) {
                            if (at === 'illuminateEmployee') {
                                at = 'Internal users'
                            }
                            items.push(at.charAt(0).toUpperCase() + at.substring(1))
                        }
                        return items
                    }, [])
                    .join(', ')
            }

            return ''
        },
        width: 300,
    },
    {
        headerName: 'SSO',
        field: 'ssoEnabled',
        sortable: false,
        cellRendererFramework: 'ChipBoolean',
        width: 125,
    },
    {
        headerName: 'Enabled',
        field: 'enabled',
        sortable: false,
        cellRendererFramework: 'ChipBoolean',
        width: 125,
    },
    {
        cellRendererFramework: 'ActionButtons',
        cellRendererParams: {
            deleteButton: {
                // actionParams: ['clientId', 'id'], // not needed because we overwrite the method
                namespace: 'authentication',
                vuexMethod: 'remove',
                title: 'Authentication',
                roles: [roles.ROLE_IMT_AUTHENTICATIONS_VIEW, roles.ROLE_SETUP_DISTRICT_VIEW],
            },
        },
        sortable: false,
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        filter: false,
    },
]
