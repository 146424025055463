import DropDown from '@/components/form/DropDown'
import RemoteData from '@/components/form/complex/RemoteData'
import TextArea from '@/components/form/TextArea'
import TextField from '@/components/form/TextField'
import ToggleButtonGroup from '@/components/form/ToggleButtonGroup'
import { VSwitch } from 'vuetify/lib'
import { humanizeStr } from '@/helpers/form/formatting'
import { required } from '@/helpers/form/validation'
import roles from '@/helpers/security/roles'

export default (provider) => {
    switch (provider) {
        case 'azure':
            return azure(provider)
        case 'classlink':
            return classlink()
        case 'clever':
            return clever()
        case 'google':
            return google()
        case 'ldap':
            return ldap()
        case 'saml':
            return saml()
        case 'illuminate_google':
            return illuminateGoogle(provider)
        default:
            return baseFields(provider)
    }
}

/**
 *
 * @param {String} provider the provider currently being used. Impacts some base options.
 * @returns {Array} array of fields for base form
 */
const baseFields = (provider) => [
    {
        component: VSwitch,
        name: 'enabled',
        label: 'Enabled',
        options: {
            props: {
                'hide-details': 'auto',
                inset: true,
                required: true,
            },
        },
    },
    {
        component: VSwitch,
        name: 'ssoEnabled',
        label: 'SSO Enabled',
        options: {
            props: {
                inset: true,
                required: true,
            },
        },
    },
    {
        component: ToggleButtonGroup,
        label: 'Account Types',
        name: 'accountTypes',
        options: {
            props: {
                buttons: accountTypes(provider),
                multiple: true,
                'hide-details': 'auto',
                required: true,
            },
        },
        rules: [required],
    },
    {
        component: TextField,
        name: 'displayName',
        label: 'Button Text',
        rules: [required],
        options: {
            props: {
                'hide-details': 'auto',
                required: true,
            },
        },
    },
    {
        component: TextField,
        name: 'name',
        label: 'Name',
        roles: [roles.ROLE_SUPER_ADMIN, 'ROLE_IMT_AUTHENTICATIONS_CREATE_ILLUMINATE_GOOGLE'],
        options: {
            props: {
                'hide-details': 'auto',
            },
        },
    },
]

const matchOn = () => ({
    component: ToggleButtonGroup,
    label: 'Match On',
    name: 'matchTo',
    options: {
        props: {
            mandatory: true,
            buttons: [
                {
                    text: 'Email',
                    name: 'email',
                },
                {
                    text: 'Username',
                    name: 'username',
                },
            ],
        },
    },
})

const accountTypes = (provider) => {
    if (provider === 'illuminate_google') {
        return [
            {
                text: 'Internal users',
                name: 'illuminateEmployee',
            },
        ]
    }
    return [
        {
            icon: 'fal fa-landmark',
            text: 'Staff',
            name: 'staff',
        },
        {
            icon: 'fal fa-user-graduate',
            text: 'Student',
            name: 'student',
        },
        {
            icon: 'fal fa-home-heart',
            text: 'Parent',
            name: 'parent',
        },
    ]
}

const azure = (provider) => [
    ...baseFields(provider),
    {
        component: TextField,
        label: 'Tenant ID',
        name: 'tenantId',
        rules: [required],
        options: {
            props: {
                'hide-details': 'auto',
                required: true,
            },
        },
    },
    matchOn(),
]

const classlink = () => [
    ...baseFields(),
    {
        component: TextField,
        label: 'Tenant ID',
        name: 'tenantId',
        rules: [required],
        options: {
            props: {
                'hide-details': 'auto',
                required: true,
            },
        },
    },

    matchOn(),
]

const clever = () => [
    ...baseFields(),
    {
        component: TextField,
        label: 'District ID',
        name: 'districtId',
        rules: [required],
        options: {
            props: {
                'hide-details': 'auto',
                required: true,
            },
        },
    },
    matchOn(),
]

const google = (provider) => [
    ...baseFields(provider),
    {
        component: TextArea,
        label: 'Allowed Domains',
        name: 'allowedDomains',
        rules: [
            required,
            (v) =>
                (v &&
                    !!v.split(/[\s,]+/).find((t) =>
                        // https://github.com/go-ozzo/ozzo-validation/blob/34bd5476bd5bb4884aee8252974da4cd4e878a75/is/rules.go
                        /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-z0-9])?\.)+(?:[a-zA-Z]{1,63}| xn--[a-z0-9]{1,59})$/.test(
                            t
                        )
                    )) ||
                'invalid format',
        ],
        options: {
            props: {
                'hide-details': 'auto',
                required: true,
            },
        },
    },
    matchOn(),
]

const illuminateGoogle = (provider) => [...baseFields(provider), matchOn()]

const ldap = () => [
    {
        column: {
            cols: 6,
        },
        fields: [
            ...baseFields(),
            {
                component: TextField,
                label: 'Host',
                name: 'host',
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
                rules: [required],
            },
            {
                component: TextField,
                label: 'Account Domain Name',
                name: 'accountDomainName',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: TextField,
                label: 'Account Domain Name Short',
                name: 'accountDomainNameShort',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: DropDown,
                label: 'SSL Type',
                name: 'sslType',
                rules: [required],
                options: {
                    props: {
                        items: ['None', 'TLS', 'SSL'],
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: TextField,
                label: 'Port',
                name: 'port',
                rules: [
                    required,
                    (v) =>
                        (parseInt(v) <= 65535 && parseInt(v) >= 1) || 'Must provide a valid port',
                ],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: DropDown,
                label: 'Account Canonical Form',
                name: 'accountCanonicalForm',
                rules: [required],
                options: {
                    props: {
                        items: [1, 2, 3, 4],
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
        ],
    },
    {
        column: {
            cols: 6,
        },
        fields: [
            {
                component: TextField,
                label: 'Test Username',
                name: 'testUsername',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: TextField,
                label: 'Test Password',
                name: 'testPassword',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: VSwitch,
                label: 'Bypass Encryption',
                name: 'bypassEncryption',
                options: { props: { inset: true } },
            },
            {
                component: TextField,
                label: 'Service Account Username',
                name: 'username',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: TextField,
                label: 'Service Account Password',
                name: 'password',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: TextField,
                label: 'Base Domain name',
                name: 'baseDn',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: TextField,
                label: 'Account Filter Format',
                name: 'accountFilterFormat',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: TextArea,
                label: 'Certertificate',
                name: 'cert',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
        ],
    },
]

const saml = () => [
    {
        column: {
            cols: 6,
        },
        fields: [
            ...baseFields(),
            {
                component: TextField,
                label: 'IDP Field',
                name: 'idpField',
                rules: [required],
                options: {
                    props: {
                        'hide-details': 'auto',
                        required: true,
                    },
                },
            },
            {
                component: ToggleButtonGroup,
                label: 'Match On',
                name: 'accountField',
                options: {
                    props: {
                        mandatory: true,
                        buttons: [
                            {
                                text: 'Email',
                                name: 'email',
                            },
                            {
                                text: 'Username',
                                name: 'username',
                            },
                        ],
                    },
                },
            },
        ],
    },
    {
        column: {
            cols: 6,
        },
        fields: [
            {
                component: RemoteData,
                label: 'IDP Metadata',
                name: 'metadata',
                options: {
                    props: {
                        rows: 15,
                    },
                },
                rules: [(v) => !!v.url || !!v.data || 'metadata or metadata URL must be provided'],
            },
        ],
    },
]
