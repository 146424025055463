<template>
    <div class="mb-5">
        <label class="v-label theme--light">
            <span class="label-wrap">{{ label }}</span>
            <template v-if="multiple">
                <v-btn-toggle
                    v-for="button in buttons"
                    :key="button.name"
                    :value="value.find((v) => v === button.name)"
                    dense
                    v-bind="$attrs"
                    @change="(e) => updateMulti(e, button.name)"
                >
                    <v-btn dense class="pa-3 mr-3" :value="button.name">
                        <v-icon color="#00AABC" size="14">{{ button.icon }}</v-icon
                        >&nbsp;<span style="text-size: 14px">{{ button.text }}</span>
                    </v-btn>
                </v-btn-toggle>
            </template>
            <template v-else>
                <v-btn-toggle
                    dense
                    v-bind="$attrs"
                    :value="value"
                    :mandatory="mandatory"
                    @change="(v) => $emit('change', v)"
                >
                    <v-btn
                        v-for="button in buttons"
                        :key="button.name"
                        :value="button.name"
                        class="pa-3"
                    >
                        <v-icon color="#00AABC" size="14">{{ button.icon }}</v-icon
                        >&nbsp;<span style="text-size: 14px">{{ button.text }}</span>
                    </v-btn>
                </v-btn-toggle>
            </template>
        </label>
    </div>
</template>

<script>
export default {
    name: 'ToggleButtonGroup',
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        buttons: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Array, String],
            default: () => [],
        },
        // currently only works for non-multiple groups
        mandatory: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateMulti(e, buttonValue) {
            // Add button value to value.
            if (e === buttonValue) {
                // This should always be true. keep unique.
                if (!this.value.find((val) => val === e)) {
                    this.$emit('change', [...this.value, e])
                }
            } else {
                this.$emit(
                    'change',
                    this.value.filter((val) => val !== buttonValue)
                )
            }
        },
    },
}
</script>

<style scoped>
.label-wrap {
    width: 150px;
    line-height: 16px;
    font-size: 14px;
    display: inline-block;
}

.v-label >>> .v-btn--active {
    background-color: #cbe1e9;
}
</style>
