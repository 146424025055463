<template>
    <div>
        <v-menu offset-y>
            <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon>fal fa-ellipsis-v</v-icon></v-btn>
            </template>
            <v-list>
                <authentication-config-form
                    :id="params.data.id"
                    :provider="params.data.provider"
                    :client-id="clientId"
                    disabled
                >
                    <template #activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" :disabled="!canView" v-on="on">
                            <v-list-item-title>
                                <v-icon size="14" color="#050F2D" style="min-width: 16px">
                                    fal fa-eye
                                </v-icon>
                                View
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </authentication-config-form>
                <authentication-config-form
                    :id="params.data.id"
                    :provider="params.data.provider"
                    :client-id="clientId"
                >
                    <template #activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" :disabled="!canEdit" v-on="on">
                            <v-list-item-title>
                                <v-icon size="14" color="#050F2D" style="min-width: 16px">
                                    fal fa-pencil
                                </v-icon>
                                Edit
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </authentication-config-form>
                <delete-dialog
                    v-if="params.deleteButton"
                    :delete-action="deleteItem"
                    :title="`Delete ${deleteTitle}?`"
                >
                    <template v-slot:default="{ on, attrs }">
                        <v-list-item color="danger" :disabled="!canDelete" v-bind="attrs" v-on="on">
                            <v-list-item-title style="vertical-align: middle">
                                <v-icon size="14" color="#050F2D" style="min-width: 16px" left>
                                    fal fa-trash
                                </v-icon>
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </delete-dialog>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AuthenticationConfigForm from './Form/AuthenticationConfigForm'
import DeleteDialog from '@/components/table/DeleteDialog'
import roles from '@/helpers/security/roles.js'
import RowDeleteActionsMixins from '@/components/table/rowDeleteActionsMixins'
import { successSnackbar } from '@/helpers/snackbar'

export default {
    name: 'ActionButtons',
    components: { AuthenticationConfigForm, DeleteDialog },
    mixins: [RowDeleteActionsMixins],
    data: () => ({ client: null }),
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            getUser: 'auth/getUser',
            getClientById: 'client/getItemById',
            districtClientId: 'getIlluminateClientId',
        }),
        clientId: function () {
            return this.$route.name === 'authentication-configs'
                ? this.districtClientId
                : this.$route.params.id
        },
        canEdit: function () {
            return this.hasRole([
                roles.ROLE_IMT_AUTHENTICATIONS_EDIT,
                roles.ROLE_SETUP_DISTRICT_EDIT,
            ])
        },
        canView: function () {
            return this.hasRole([
                roles.ROLE_IMT_AUTHENTICATIONS_VIEW,
                roles.ROLE_SETUP_DISTRICT_VIEW,
            ])
        },
    },
    mounted() {
        this.client = this.getClientById(this.clientId)
    },
    methods: {
        ...mapActions('authentication', ['remove', 'cget']),
        //Overwrite RowDeleteActionsMixins method
        deleteItem: async function () {
            await this.remove({ clientId: this.clientId, id: this.params.data.id })
            this.cget({ clientId: this.clientId })

            successSnackbar({
                subtext: `${this.deleteTitle} for ${this.params.data.provider} removed`,
            })
        },
    },
}
</script>
