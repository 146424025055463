<template>
    <div class="ag-theme-material">
        <ag-grid-vue
            :row-data="rowData"
            :grid-options="gridOptions"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from '@ag-grid-community/vue'
import ChipBoolean from './ChipBoolean'
import ActionButtons from "@/views/Imt/Authentications/ActionButtons";

export default {
    name: 'AgGridSimple',
    components: {
        AgGridVue,
        ChipBoolean,
        ActionButtons,
    },
    props: {
        columnDefs: {
            type: Array,
            default() {
                return []
            },
        },
        rowData: {
            type: Array,
            default() {
                return []
            },
        },
    },
    data: () => ({
        gridOptions: {},
        defaultColDef: null,
    }),
    beforeMount() {
        this.gridOptions = {
            domLayout: 'autoHeight',
        }
        this.defaultColDef = {
            sortable: false,
            resizable: false,
            filter: false,
        }
    },
}
</script>