<template>
    <div class="mt-4">
        <text-field
            ref="url"
            v-bind="$attrs"
            :value="value.url"
            :label="urlLabel"
            class="url"
            :rules="formRules"
            hide-details="auto"
            @blur="clearErrors({ data: value.data, url: value.url })"
            @input="(url) => changed({ url: url, data: value.data })"
        >
            <template v-slot:append-outer>
                <v-btn color="primary" @click="getContent">Fetch</v-btn>
            </template>
        </text-field>

        <text-area
            ref="data"
            :label="label"
            :value="value.data"
            :rows="rows"
            :rules="formRules"
            @blur="clearErrors({ data: value.data, url: value.url })"
            @input="(data) => changed({ url: value.url, data: data })"
        ></text-area>
    </div>
</template>

<script>
import TextArea from '../TextArea'
import TextField from '../TextField'
import { mapActions } from 'vuex'

/**
 * This component contains two fields.
 *
 * One field can pull data from an endpoint and populate the second field. The second field may also be populated without the first field.
 *
 * v-model will be in the structure:
 *
 * {
 *  url:
 *  data:
 * }
 */
export default {
    name: 'RemoteData',
    components: {
        TextArea,
        TextField,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                url: '',
                data: '',
            }),
        },
        label: {
            type: String,
            default: '',
        },
        rows: {
            type: [Number, String],
            default: undefined,
        },
        rules: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        urlLabel() {
            return `${this.label} URL`
        },
        formRules() {
            const r = this.rules.map((rule) => (v) => rule(this.value))

            return r
        },
    },
    methods: {
        ...mapActions('authentication', ['fetchMetadata']),
        async getContent() {
            try {
                const resp = await this.fetchMetadata({ url: this.value.url })
                this.changed({ url: this.value.url, data: resp.data })
            } catch (e) {}
        },
        clearErrors({ data, url }) {
            /*
             * Because both of these fields are validated when either is updated the error state will get out of
             * sync when both fields have validation errors. Only one field will clear its validation when its updated
             *  - e.g. url and data fields have values then they are both cleared. both will show errors. url is then filed out
             *         and the error clears on url. data will still show an error.
             *
             * This condition will clear the validation for the whole form when url OR data have values. This isn't ideal but with
             * vuetify forms there isn't an api to clear an error on a single field. Caveats / gotchas with this are other fields
             * that show errors will be cleared when these two fields are modified. The form submit button will be grey / disabled
             * but the field will still show an asterisk.
             */
            if (data || url) {
                this.$emit('clearValidation', {})
            }
        },
        changed(payload) {
            if (payload.data || payload.url) {
                this.clearErrors({ ...payload })
            }

            this.$emit('input', payload)
        },
    },
}
</script>

<style scoped>
.url >>> .v-input__append-outer {
    margin-top: 0;
}
</style>
