<template>
    <v-dialog v-model="dialog" :max-width="maxWidth">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>
        <authentication-provider
            :id="id"
            :client-id="clientId"
            :provider="provider"
            @submitted="dialog = false"
        >
            <form-factory
                :id="id"
                ref="form"
                :title="title"
                :inputs="configFields"
                v-bind="$attrs"
                @closed="dialog = false"
            />
        </authentication-provider>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { providerNameMap } from '@/helpers/shared'
import roles from '@/helpers/security/roles.js'

import FormFactory from '@/components/formFactory/FormFactory'
import AuthenticationProvider from './AuthenticationProvider'
import authConfigFields from '@/helpers/form/definitions/auth-configs'

export default {
    name: 'AuthenticationConfigForm',
    components: {
        FormFactory,
        AuthenticationProvider,
    },
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: '',
        },
        provider: {
            type: String,
            required: true,
        },
        create: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        // ID of authentication to fetch, leave blank to create a new authentication.
        id: {
            type: String,
            default: null,
        },
        clientId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            roles,
            dialog: null,
        }
    },
    computed: {
        ...mapGetters('authentication', ['getItemsById', 'getDraft']),
        ...mapGetters({ selectedClient: 'clients/getSelected' }),
        maxWidth: function () {
            if (['saml', 'ldap'].includes(this.provider)) {
                return '1360px'
            }
            return '696px'
        },
        title: function () {
            const name = providerNameMap(this.provider)
            if (this.create) {
                return `Create | ${name} Authentication`
            }

            return `${this.readonly ? 'View' : 'Edit'} | Authentication ${name}`
        },
        configFields: function () {
            return authConfigFields(this.provider)
        },
    },
    watch: {
        dialog(d) {
            // clear if this is a new authentication form.
            if (!d && !this.id) {
                this.$refs.form.reset()
                // re-initialize forms if they have an id.
            } else if (d && this.id && this.$refs.form) {
                this.$refs.form.setup()
            }
        },
    },
}
</script>
