var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}])},[_c('v-list',[_c('authentication-config-form',{attrs:{"id":_vm.params.data.id,"provider":_vm.params.data.provider,"client-id":_vm.clientId,"disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":!_vm.canView}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"min-width":"16px"},attrs:{"size":"14","color":"#050F2D"}},[_vm._v(" fal fa-eye ")]),_vm._v(" View ")],1)],1)]}}])}),_c('authentication-config-form',{attrs:{"id":_vm.params.data.id,"provider":_vm.params.data.provider,"client-id":_vm.clientId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":!_vm.canEdit}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"min-width":"16px"},attrs:{"size":"14","color":"#050F2D"}},[_vm._v(" fal fa-pencil ")]),_vm._v(" Edit ")],1)],1)]}}])}),(_vm.params.deleteButton)?_c('delete-dialog',{attrs:{"delete-action":_vm.deleteItem,"title":("Delete " + _vm.deleteTitle + "?")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"color":"danger","disabled":!_vm.canDelete}},'v-list-item',attrs,false),on),[_c('v-list-item-title',{staticStyle:{"vertical-align":"middle"}},[_c('v-icon',{staticStyle:{"min-width":"16px"},attrs:{"size":"14","color":"#050F2D","left":""}},[_vm._v(" fal fa-trash ")]),_vm._v(" Delete ")],1)],1)]}}],null,false,2177201152)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }