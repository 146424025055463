<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="!hasCreateRole" v-bind="attrs" class="primary ml-4" v-on="on">
                Create Config
            </v-btn>
        </template>
        <v-list>
            <authentication-config-form
                v-for="(item, idx) in items"
                :key="idx"
                :provider="item.provider"
                :client-id="clientId"
                create
            >
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </template>
            </authentication-config-form>
        </v-list>
    </v-menu>
</template>
<script>
import AuthenticationConfigForm from './Form/AuthenticationConfigForm'
import roles from '@/helpers/security/roles.js'
import { mapGetters } from 'vuex'
import { providerNames } from '@/helpers/shared'

export default {
    components: { AuthenticationConfigForm },
    props: {
        clientId: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters('auth', ['hasRole']),
        items: function () {
            return providerNames.filter((item) => {
                return (
                    item.provider !== 'illuminate_google' ||
                    this.hasRole([roles.ROLE_IMT_AUTHENTICATIONS_CREATE_ILLUMINATE_GOOGLE])
                )
            })
        },
        hasCreateRole: function () {
            return this.hasRole([
                roles.ROLE_IMT_AUTHENTICATIONS_CREATE,
                roles.ROLE_SETUP_DISTRICT_CREATE,
            ])
        },
    },
}
</script>
